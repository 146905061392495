import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e47f98e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu-wrapper" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 4 }
const _hoisted_5 = { key: 5 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SwitcherCheckbox = _resolveComponent("SwitcherCheckbox")!
  const _component_BurgerModal = _resolveComponent("BurgerModal")!

  return (_openBlock(), _createBlock(_component_BurgerModal, {
    "show-close": true,
    top: "",
    open: _ctx.isMenuOpen,
    closable: true,
    onClose: _ctx.closeMenu
  }, {
    default: _withCtx(() => [
      _createElementVNode("ul", _hoisted_1, [
        _createElementVNode("li", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showDrawsModal && _ctx.showDrawsModal(...args)))
        }, _toDisplayString(_ctx.$t("drawHistory")), 1),
        _createElementVNode("li", {
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showRulesModal && _ctx.showRulesModal(...args)))
        }, _toDisplayString(_ctx.$t("rules")), 1),
        (_ctx.isGambusaki)
          ? (_openBlock(), _createElementBlock("li", {
              key: 0,
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.showSettings && _ctx.showSettings(...args)))
            }, _toDisplayString(_ctx.$t("autoGame")), 1))
          : _createCommentVNode("", true),
        (_ctx.autoSettings)
          ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
              _createVNode(_component_SwitcherCheckbox, {
                label: _ctx.$t('autoplaySettingsAutoBonusStart'),
                checked: _ctx.autoStart,
                "onUpdate:checked": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.autoStart) = $event))
              }, null, 8, ["label", "checked"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.autoSettings)
          ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
              _createVNode(_component_SwitcherCheckbox, {
                label: _ctx.$t('autoplaySettingsAutoContinuation'),
                checked: _ctx.autoContinuation,
                "onUpdate:checked": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.autoContinuation) = $event))
              }, null, 8, ["label", "checked"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.isHaveBackgroundMusic)
          ? (_openBlock(), _createElementBlock("li", {
              key: 3,
              onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.showSoundSettings && _ctx.showSoundSettings(...args)))
            }, _toDisplayString(_ctx.$t("soundSettings")), 1))
          : _createCommentVNode("", true),
        (_ctx.soundSettings)
          ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
              _createVNode(_component_SwitcherCheckbox, {
                label: _ctx.$t('soundSettingsSoundEffects'),
                checked: _ctx.soundEffects,
                "onUpdate:checked": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.soundEffects) = $event))
              }, null, 8, ["label", "checked"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.soundSettings)
          ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
              _createVNode(_component_SwitcherCheckbox, {
                label: _ctx.$t('soundSettingsBackgroundMusic'),
                checked: _ctx.backgroundMusic,
                "onUpdate:checked": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.backgroundMusic) = $event))
              }, null, 8, ["label", "checked"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["open", "onClose"]))
}